/* styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.fraunces-script-semibold {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-style: normal;
}

@layer base {
  .icon-sm {
    @apply w-4 h-4;
  }
  .icon-md {
    @apply w-6 h-6;
  }
  .icon-lg {
    @apply w-8 h-8;
  }
  .icon-xl {
    @apply w-10 h-10;
  }
}

@layer components {
  /* Apply Playfair Display to headings */
  h1,
  h2,
  h3,
  h4 {
    font-family: "Playfair Display", serif; /* Use Playfair Display for headings */
  }

  h1 {
    @apply text-h1 font-semibold text-brand-800;
  }

  h2 {
    @apply text-h2 font-semibold text-textTitle;
  }

  h3 {
    @apply text-h3 font-semibold text-textTitle;
  }

  h4 {
    @apply text-h4;
  }

  h5 {
    @apply text-h5;
  }

  h6 {
    @apply text-h6;
  }

  p {
    @apply text-body;
  }

  span {
    @apply text-body;
  }

  /* Apply Source Sans Pro to body text and specific headings */
  body,
  h5,
  h6 {
    font-family: "Source Sans Pro", sans-serif; /* Use Source Sans Pro for body and h5, h6 */
  }

  /* Additional styles for body */
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .system-container {
    @apply bg-surfaceSystem box-border min-h-screen flex flex-col;
  }

  .button-text {
    @apply text-h6 font-semibold text-brand-800 hover:underline hover:text-brand-900 px-4 py-2;
  }

  input {
    @apply w-full px-5 py-4 text-h6
    text-textPlaceholder 
    rounded-lg 
    border border-borderLight 
    bg-surfaceTwo;
  }

  input:focus {
    @apply outline-none border-brand-800;
  }

  /* Navbar Styling*/
  .navbar {
    @apply flex items-center justify-between px-desktopMargin py-4 sticky top-0 z-50 bg-surfaceSystem;
  }

  .navbar-left {
    @apply flex flex-row items-center gap-2;
  }

  .navbar-right {
    @apply flex flex-row items-center gap-3;
  }

  .menu-button {
    @apply hidden;
  }

  .logo-img {
    @apply w-10 h-10;
  }

  .brand-name {
    @apply text-2xl fraunces-script-semibold font-semibold text-brand-800;
  }

  /*hero section styling*/
  .hero-section {
    @apply flex flex-row items-center justify-between px-desktopMargin gap-12 py-12;
  }

  .hero-left {
    @apply flex flex-col items-start justify-start gap-2;
  }

  .hero-right {
    @apply flex flex-col items-end justify-center;
  }

  .hero-subtitle {
    @apply text-lg text-brand-800;
  }

  .hero-image {
    @apply h-full min-w-60 w-80 rounded-3xl object-cover shadow-imageShadow;
  }

  /*about me section styling*/
  .about-me {
    @apply flex flex-row items-center justify-center gap-8 mx-desktopMargin px-16 py-16;
  }

  .about-me-title {
    @apply text-h3 font-semibold text-brand-800 w-full;
  }

  .about-me-content {
    @apply flex flex-col items-start justify-start gap-4;
  }

  .about-me-image {
    @apply h-full min-w-40 w-64 rounded-lg object-cover shadow-imageShadow;
  }

  /*clarity call section styling*/
  .clarity-call {
    @apply flex flex-row items-center justify-center px-desktopMargin py-20;
  }
  .clarity-call-container {
    @apply flex flex-row items-center justify-center w-full bg-white px-12 py-12 rounded-3xl gap-12;
  }

  .clarity-call-content {
    @apply flex flex-col items-start justify-center gap-2;
  }

  .clarity-call-image {
    @apply min-w-48 w-72 rounded-3xl object-cover;
  }

  .clarity-section-subtitle {
    @apply text-h6 text-brand-800;
  }

  /*why section styling*/
  .why-section {
    @apply flex flex-row items-center justify-center mx-desktopMargin py-20;
  }

  .why-content {
    @apply flex flex-row items-center justify-center gap-12 w-full bg-white px-12 py-12 rounded-3xl;
  }

  .why-content-left {
    @apply flex flex-col items-start justify-start gap-2 w-full;
  }

  .why-content-right {
    @apply flex items-end justify-center;
  }

  .why-image {
    @apply h-full min-w-40 w-64 rounded-lg object-cover;
  }

  /*booking form styling*/
  .booking-form {
    @apply flex flex-col items-stretch mx-desktopMargin py-12;
  }

  .booking-form-container {
    @apply flex flex-col w-full justify-stretch items-stretch bg-white rounded-3xl py-12 gap-4 px-16 max-w-4xl;
  }

  .booking-form-input {
    @apply w-full;
  }

  .booking-form-textarea {
    @apply w-full px-4 py-3 mt-2 
    outline-none rounded-lg 
    border
    border-borderLight bg-surfaceTwo 
    text-h6 text-textPlaceholder 
    focus:border-brand-800;
  }

  .booking-form-button {
    @apply w-full;
  }

  /*booking form radio styling*/
  .booking-form-radio {
    @apply flex flex-row items-center justify-start gap-2;
  }
  .booking-form-radio-container {
    @apply flex flex-row items-center justify-start gap-6 w-full py-2;
  }

  .booking-form-date-container {
    @apply flex flex-col items-start justify-start gap-1 w-full;
  }

  .booking-form-radio-option {
    @apply flex flex-row items-center justify-start gap-3 w-auto;
  }

  .options-container {
    @apply flex flex-row items-center justify-start gap-6 w-auto;
  }

  .booking-form-radio-label {
    @apply font-semibold text-brand-800 hover:underline hover:text-brand-900;
  }

  .testimonial-section {
    @apply flex flex-col items-center justify-center gap-8 mx-desktopMargin py-20;
  }

  .testimonial-section-header {
    @apply flex flex-col items-center justify-center gap-4 text-center;
  }

  .testimonial-section-body {
    @apply flex flex-row items-start justify-center gap-8 text-center;
  }

  .testimonial-container {
    @apply flex flex-col items-center justify-center gap-4 text-center w-1/3;
  }

  .testimonial-image {
    @apply h-32 min-w-32 w-32 rounded-full object-cover;
  }

  /*footer section styling*/
  .footer-section {
    @apply flex flex-col items-center justify-center gap-4 mx-desktopMargin py-12;
  }

  .footer-section-links {
    @apply flex flex-row items-center justify-center gap-12;
  }

  .footer-section-link {
    @apply flex flex-row items-center justify-center gap-2;
  }

  .footer-section-link img {
    @apply w-6 h-6;
  }

  .footer-section-link a {
    @apply text-h6 text-brand-800 font-semibold hover:underline hover:text-brand-800;
  }

  .footer-section-link a:hover {
    @apply text-brand-800;
  }

  .footer-section-link a:active {
    @apply text-brand-800;
  }

  .footer-section-link a:visited {
    @apply text-brand-800;
  }

  @media (max-width: 854px) {
    /*text styles*/
    h6 {
      @apply text-mobileH6;
    }

    h5 {
      @apply text-mobileH5;
    }

    h4 {
      @apply text-mobileH4;
    }

    h3 {
      @apply text-mobileH3;
    }

    h2 {
      @apply text-mobileH2;
    }

    h1 {
      @apply text-mobileH1;
    }

    p {
      @apply text-mobileBody;
    }

    span {
      @apply text-mobileBody;
    }

    textarea {
      @apply px-4 py-3 h-64;
    }

    input {
      @apply px-4 py-3;
    }

    button {
      @apply w-full px-2 py-1;
    }

    /*navbar styling*/
    .navbar {
      @apply px-mobileMargin;
    }

    .navbar-right {
      @apply hidden;
    }

    .navbar-right-mobile {
      @apply block;
    }

    .menu-button {
      @apply block;
    }

    .logo-img {
      @apply w-8 h-8;
    }

    .brand-name {
      @apply text-lg;
    }

    .hero-section {
      @apply flex-col-reverse items-start justify-start gap-4 py-8 px-8;
    }

    .hero-image {
      @apply min-w-40 w-60;
    }

    .why-section {
      @apply mx-mobileMargin;
    }

    .why-content {
      @apply flex-col items-center justify-start gap-4 px-8 py-8;
    }

    .about-me {
      @apply flex-col mx-mobileMargin px-4 py-24;
    }

    .about-me-title {
      @apply text-h3 text-center;
    }

    .about-me-text {
      @apply text-h6 text-center;
    }

    .clarity-call {
      @apply w-full py-8 px-8;
    }

    .why-section {
      @apply mx-mobileMargin;
    }

    .clarity-call-container {
      @apply px-8 py-8 gap-4 flex-col;
    }

    .booking-form {
      @apply py-24 mx-mobileMargin;
    }

    .booking-form-container {
      @apply px-8 py-8 gap-4 flex-col;
    }

    .booking-form-radio-container {
      @apply flex-col gap-4 justify-start items-start;
    }

    .options-container {
      @apply flex-col gap-4 justify-start items-start;
    }

    .dropdown-modal {
      @apply flex flex-col items-start justify-start gap-2;
    }

    .testimonial-section {
      @apply mx-mobileMargin py-8;
    }

    .testimonial-section-body {
      @apply flex-col;
    }

    .testimonial-container {
      @apply flex-col w-full;
    }

    .footer-section-links {
      @apply flex-col gap-4;
    }

    .dropdown-modal button {
      @apply w-full hover:bg-surfaceSystem;
    }
  }
}

/* This section is currently empty. Please provide the necessary styles or components to be included here. */
